import { SiNodeDotJs } from "@react-icons/all-files/si/SiNodeDotJs";
import { SiWordpress } from "@react-icons/all-files/si/SiWordpress";
import { SiJavascript, SiReact } from "react-icons/si";
import { SiPhp } from "react-icons/si";
import { SiShopify } from "react-icons/si";
import { FaSearch } from "react-icons/fa";
import { SiNextdotjs } from "react-icons/si";
import { useTranslation } from "react-i18next";

export const PROJECTS = () => {
  const { t } = useTranslation("portfolio");

  return [
    {
      name: t("portfolio.projects.adas.name"),
      featuredImage: "/images/projects/adas.png",
      description: t("portfolio.projects.adas.description"),
      tech: [
        { name: "React Native", Icon: () => <SiReact className="h-4 w-4" /> },
        { name: "Node.js", Icon: () => <SiNodeDotJs className="h-4 w-4" /> },
      ],
      type: [t("portfolio.projects.adas.type.mobile"), t("portfolio.projects.adas.type.react")],
      year: "2021",
      url: "https://adas.app/en/",
      repository: null,
    },
    {
      name: t("portfolio.projects.aero.name"),
      featuredImage: "/images/projects/Aeroschool.png",
      description: t("portfolio.projects.aero.description"),
      tech: [
        { name: "WordPress", Icon: () => <SiWordpress className="h-4 w-4" /> },
        { name: "PHP", Icon: () => <SiPhp className="h-4 w-4" /> },
      ],
      type: [t("portfolio.projects.aero.type.web"), t("portfolio.projects.aero.type.cms")],
      year: "2020",
      url: "https://aeroschoolkhouribga.ma/",
      repository: null,
    },
    {
      name: t("portfolio.projects.homypay.name"),
      featuredImage: "/images/projects/Homepay.png",
      description: t("portfolio.projects.homypay.description"), // Add a description key to translations
      tech: [
        { name: "WordPress", Icon: () => <SiWordpress className="h-4 w-4" /> },
        { name: "PHP", Icon: () => <SiPhp className="h-4 w-4" /> },
        { name: "JavaScript", Icon: () => <SiJavascript className="h-4 w-4" /> },
      ],
      type: [
        t("portfolio.projects.homypay.type.web"),
        t("portfolio.projects.homypay.type.fintech"),
        t("portfolio.projects.homypay.type.marketplace"),
      ],
      year: "2020",
      url: "https://homypay.com/",
      repository: null,
    },
    {
      name: t("portfolio.projects.centralrisk.name"),
      featuredImage: "/images/projects/CentralRisk.png",
      description: t("portfolio.projects.centralrisk.description"), 
      tech: [
        { name: "Wordpress", Icon: () => <SiWordpress className="h-4 w-4" /> },
        { name: "PHP", Icon: () => <SiPhp className="h-4 w-4" /> },
      ],
      type: [
        t("portfolio.projects.centralrisk.type.web"),
        t("portfolio.projects.centralrisk.type.analytics"),
      ],
      year: "2023",
      url: "https://centralrisk.online/",
      repository: null,
    },
    {
      name: t("portfolio.projects.mamantendre.name"),
      featuredImage: "/images/projects/lamamantendre.png",
      description: t("portfolio.projects.mamantendre.description"),
      tech: [
        { name: "Shopify", Icon: () => <SiWordpress className="h-4 w-4" /> },
        { name: "Javascript", Icon: () => <SiJavascript className="h-4 w-4" /> },
      ],
      type: [
        t("portfolio.projects.mamantendre.type.ecommerce"),
        t("portfolio.projects.mamantendre.type.seo"),
      ],
      year: "2022",
      url: "http://lamamantendre.com/",
      repository: null,
    },
    {
      name: t("portfolio.projects.weather.name"),
      featuredImage: "/images/projects/weatherapp.png",
      description: t("portfolio.projects.weather.description"),
      tech: [
        { name: "React", Icon: () => <SiReact className="h-4 w-4" /> },
        { name: "Next.js", Icon: () => <SiNextdotjs className="h-4 w-4" /> },
        { name: "Node.js", Icon: () => <SiNodeDotJs className="h-4 w-4" /> },
      ],
      type: [t("portfolio.projects.weather.type.web")],
      year: "2024",
      repository: "https://github.com/AyoubOUASSAF/weather-app",
    },
  ];
};
