import { About } from "components/sections/about";
import { Hero } from "components/sections/hero";
import { PortfolioPreview } from "components/sections/portfolio-preview";
import { Timeline } from "components/sections/timeline";
import { GetStaticProps } from "next";
import party from "party-js";
import { FC } from "react";
import { Client } from "twitter-api-sdk";
import { components } from "twitter-api-sdk/dist/gen/openapi-types";

type IndexProps = {
  twitterData: components["schemas"]["User"] | null; // Allow null for fallback
};

party.settings.respectReducedMotion = false;

export const Index: FC<IndexProps> = ({ twitterData }) => {
  return (
    <>
      <Hero twitterData={twitterData} />
      <About />
      <Timeline />
      <PortfolioPreview />
    </>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  let twitterData: components["schemas"]["User"] | null = null;

  try {
    const client = new Client(process.env.TWITTER_CLIENT_BEARER_TOKEN as string);
    const response = await client.users.findUserByUsername("ayoubouassaf", {
      "user.fields": [
        "created_at",
        "description",
        "entities",
        "id",
        "location",
        "name",
        "pinned_tweet_id",
        "profile_image_url",
        "protected",
        "public_metrics",
        "url",
        "username",
        "verified",
        "withheld",
      ],
    });

    twitterData = response.data as components["schemas"]["User"];
  } catch (error) {
    console.error("Error fetching Twitter data:", error);
  }

  return {
    props: { twitterData }, // Pass null if the API call fails
    revalidate: 300, // Revalidate every 5 minutes
  };
};

export default Index;
