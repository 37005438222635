import heroCode from "content/code-blocks/hero";
import { SiNodeDotJs } from "@react-icons/all-files/si/SiNodeDotJs";
import { SiReact } from "@react-icons/all-files/si/SiReact";
import { useTranslation } from "react-i18next";
import { SiCss3, SiHtml5, SiTailwindcss, SiWordpress } from "react-icons/si";

export const HERO = () => {
  const { t } = useTranslation("hero");

  return {
    pre: t("pre"),
    heading: (
      <>
        {t("heading.intro")} <strong>{t("heading.name")}</strong>, {t("heading.title")}
      </>
    ),
    body: (
      <>
        {t("body")} <br />
        {t("body.details")}
      </>
    ),
    tech: [
      { name: "React", Icon: ({ className }: { className: string }) => <SiReact className={className} /> },
      { name: "Node.js", Icon: ({ className }: { className: string }) => <SiNodeDotJs className={className} /> },
      { name: "Wordpress", Icon: ({ className }: { className: string }) => <SiWordpress className={className} /> },
      { name: "HTML", Icon: ({ className }: { className: string }) => <SiHtml5 className={className} /> },
      { name: "CSS", Icon: ({ className }: { className: string }) => <SiCss3 className={className} /> },
      { name: "Tailwind", Icon: ({ className }: { className: string }) => <SiTailwindcss className={className} /> },
    ],
    cta1: { href: "mailto:ouassaf.ayoub@gmail.com", name: t("cta1") },
    cta2: { href: "/resume", name: t("cta2") },
    badges: [
      t("badges.driving"),
      t("badges.traveling"),
      t("badges.food_enthusiast"),
      t("badges.gamer"),
      t("badges.adventure_seeker"),
      t("badges.coding"),
    ],
    code: heroCode, // Include heroCode
    support: t("support"), // Add support translation
  };
};
