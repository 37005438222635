const heroCode = `
  import Welcome from "./Welcome";

  const App = () => {
    return (
      <Welcome
        uses="explore new tech"
        motivation="Every big dream starts with a small step."
        aspiration="build a platform that inspires and empowers others"
      />
    );
  };

  export default App;
`;

export default heroCode;
