import DevCoding from "../public/images/about/dev-coding.jpg";
import CodeMeetup from "../public/images/about/code-meetup.jpg";
import { useTranslation } from "react-i18next";
import type { StaticImageData } from "next/image";

interface AboutData {
  stats: {
    statistic: string;
    tooltip: string;
    caption: string;
  }[];
  description: JSX.Element;
  images: {
    src: StaticImageData;
    alt: string;
  }[];
}

export const ABOUT = (): AboutData => {
  const { t } = useTranslation("about");

  return {
    stats: [
      {
        statistic: `${new Date().getFullYear() - new Date("1993-03-12T01:30:00").getFullYear()}`,
        tooltip: t("stats.years_old.tooltip"),
        caption: t("stats.years_old.caption"),
      },
      {
        statistic: "7+",
        tooltip: t("stats.years_development.tooltip"),
        caption: t("stats.years_development.caption"),
      },
      {
        statistic: "200+",
        tooltip: t("stats.commits.tooltip"),
        caption: t("stats.commits.caption"),
      },
    ],
    description: (
      <>
        <p>{t("description.paragraph1")}</p>
        <p>{t("description.paragraph2")}</p>
        <p>{t("description.paragraph3")}</p>
      </>
    ),
    images: [
      {
        src: DevCoding,
        alt: t("images.dev_coding"),
      },
      {
        src: CodeMeetup,
        alt: t("images.code_meetup"),
      },
    ],
  };
};
